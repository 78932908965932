<template >
  <div>
    <Loading v-if="loading"></Loading>
    <div v-if="!loading">
        <!-- ======= Breadcrumbs ======= -->
    <section class="breadcrumbs">
      <div class="container">

        <ol>
          <li><a @click="routeTo('home')">Home</a></li>
          <li><a >Knowledge</a></li>
          
        </ol>
        <h2>Knowledge</h2>

      </div>
    </section>
    

    <section id="portfolio" class="portfolio portfolio-details blog" style="margin:0;padding:0">
 
      <div class="container" data-aos="fade-up">
        <header class="section-header " style="height: 150px;margin:0">
         
          
         
       
         

          <div class="col-lg-12">
          
            <div class="sidebar row center-element" style="margin:0;padding:5px">
            <div class="col-md-12" style="padding:10px">
            Explore our library of knowledge products
            <br>
            </div>
              <div class="row col-md-6 col-sm-12 daynamic-height">
                <h3 class="sidebar-title col-md-3" style="text-align: start;margin:0">
                  Sectors
                </h3>
                <div class="row col-md-9">

<div class="sidebar-item categories col-7" style="margin:0">
                   <b-form-select v-model="selctedSector" class="mb-3">
      <b-form-select-option v-for="(sector,index) in sectors" :key="index" :value="sector.id">{{sector.header}}</b-form-select-option>
     
    </b-form-select>
                
              </div>
              <div class="col-1" style="margin:0">
                <button class="select-search-bar button-pill" @click="filterKnowledge" type="submit">
                  <i class="bi bi-funnel"></i>Filter
                </button>
                
              </div>
                </div>
                
              </div>

              <div class="col-md-1"></div>
              <div class="col-md-5 col-sm-12 daynamic-height">
               
              <div class="sidebar-item search-form" style=" border-radius: 25px;">
                
                  <input type="text" v-model="searchInput" placeholder="Search">
                  <button style="    border-bottom-right-radius: 25px;
    border-top-right-radius: 25px;" type="submit" @click="search"><i class="bi bi-search"></i></button>
                
              </div>
              </div>

               
            </div>
          </div>
          

        </header>
        <span >
          <h3 class="text-center" v-if="searchResult"> Knowledge search result(s) for "{{searchInput}}"</h3>
        <div class="row"  >
          <br>
          <div class="col-lg-12 d-flex justify-content-center">
            <ul id="portfolio-flters">
              <li data-filter="*" class="filter-active" @click="all">All</li>
              <li data-filter=".filter-app" @click="pdf">PDF</li>
              <li data-filter=".filter-card" @click="video">Video</li>
            </ul>
          </div>
        </div></span>
        
        <div class="error-message text-center" v-if="items && !items.length">
            No result has been found
        </div>
        <Loading v-if="Contentloading"></Loading>
        <div v-if="!Contentloading" id="list-complete-demo" class="demo">
          
          
          <b-row >
        <b-col md="3" v-for="(item,index) in items"
              :key="index"
              style="padding:10px"
              >
       
          <b-card
            class="pdf-card-kn"
            style="height:100%;background:#013289;
            border:solid 1px #013289;cursor:pointer
            "
            @click="routeTo('knowledge_materials',item)"

    :img-src="item.tumblin"
    
          >
          <div style="height: 110%; font-wight:900" class="center-element white-text">{{item.header}}</div>
          
       
          </b-card>
        </b-col>
        </b-row>
            <!-- <span
              v-for="(item,index) in items"
              :key="index"
              class="list-complete-item col-md-4 portfolio-item"
              
            >
            
              <div class="portfolio-wrap" style="height:330px" @click="routeTo('knowledge_materials',item)">
                <img
                  v-bind:src="item.tumblin"
                  class="img-fluid"
                  alt=""
                  height="330px"
                  width="100%"
                />
                <div class="portfolio-info">
                  <h4>{{item.header}}</h4>
                  
                    
                
                </div>
              </div>
            </span> -->
        
        </div>
        
      </div>
      
      <br><br>
          <b-pagination
            
      @input="pageChange(currentPage)"
      v-model="currentPage"
     
      align="center"
      :total-rows="rows"
      :per-page="perPage"
    
    ></b-pagination>
    
        

    </section>
  </div>
  </div>
  
</template>
<script>
import { getComponentData, getFields, getFieldsPage, getPerPage, getSectorsKnowledge, postDataHeader } from '../assets/js/service';
import Loading from './container/Loading.vue'
export default {  
  metaInfo: {
    // Children can override the title.
    title: 'Knowledge',
    // Result: My Page Title ← My Site
    // If a child changes the title to "My Other Page Title",
    // it will become: My Other Page Title ← My Site
    titleTemplate: '%s ← First Consult',
    // Define meta tags here.
    meta: [
{property:"og:site_name" ,content:"First Consult Knowledge"},
      {property:"og:title" ,content:"First Consult Knowledge"},
      {property:"og:description" ,content:"First Consult is a leading economic development consulting firm implementing projects in Ethiopia. Founded in 2006, First Consult has grown to design and implement projects across the agriculture, manufacturing and service sectors. Our multidisciplinary teams combine a capacity to execute with a clarity of the local context. We have delivered at-scale real impact in terms of jobs & wealth creation, business formation & growth, and investment attraction & mobilisation."},
      {property:"og:image" ,content:"http://firstconsultet.com/img/favicons.fb3e6239.png"},
      {property:"og:url" ,content:"https//www.firstconsulet.com/knowledge"},


      {name:"twitter:title" ,content:"First Consult Knowledge"},
      {name:"twitter:description" ,content:"First Consult is a leading economic development consulting firm implementing projects in Ethiopia. Founded in 2006, First Consult has grown to design and implement projects across the agriculture, manufacturing and service sectors. Our multidisciplinary teams combine a capacity to execute with a clarity of the local context. We have delivered at-scale real impact in terms of jobs & wealth creation, business formation & growth, and investment attraction & mobilisation."},
      {name:"twitter:image" ,content:"http://firstconsultet.com/img/favicons.fb3e6239.png"},
      {property:"twitter:url" ,content:"https//www.firstconsulet.com/knowledge"},
      {name:"twitter:card" ,content:"summary"},

      {'http-equiv': 'Content-Type', content: 'text/html; charset=utf-8'},
      {name: 'description', content: 'First Consult is a leading economic development consulting firm implementing projects in Ethiopia. Founded in 2006, First Consult has grown to design and implement projects across the agriculture, manufacturing and service sectors. Our multidisciplinary teams combine a capacity to execute with a clarity of the local context. We have delivered at-scale real impact in terms of jobs & wealth creation, business formation & growth, and investment attraction & mobilisation.'}
    ],
    script: [
                { src: 'https://platform.twitter.com/widgets.js', async: true },
            ],
  },
  
  components:{

  
Loading
  },
  data() {
    return {
      loading:false,
      allItems: [1, 2, 3, 4, 5, 6, 7, 8, 9],
      items:null,
      nextNum: 10,
      Contentloading:false,
      selctedSector:null,
      allKnowledge:[],
      searchInput:null,
searchResult:null,
      sectors:[],
        currentPage: 1,
        
      rows: 1,
      perPage: 1,
    };
  },
  mounted() {
    let token = localStorage.getItem('token');
this.$store.commit('set',['loading',true]);
    getFields('Knowledge/count',token).then(resp=>{
      this.rows = Math.ceil(resp.data.count / getPerPage());
      getFieldsPage('Knowledge',1,token).then(resp=>{
   this.items = resp.data;
      this.allKnowledge = resp.data;
      })
    })
    getFields('Sectors',token).then(
      resp=>{
        this.sectors = resp.data;this.$store.commit('set',['loading',false]);
      }
    )
  }, watch:{
     "$store.state.loading": {
      handler() {
        this.loading = this.$store.state.loading;
        if(!this.loading){
          this.Contentloading = false;
        }
      },
     }
    },
  methods: {  
    search(){
         this.Contentloading = true;
         postDataHeader('Knowledge/search',this.searchInput,false).then(resp=>{

           this.searchResult = true;
           this.items = resp.data.Data;
           this.Contentloading = false;
      this.allKnowledge = resp.data.Data;
         })
       },
     pageChange(currentPage){
      let token = localStorage.getItem('token');
      getFieldsPage('Knowledge',currentPage,token).then(resp=>{
    this.items = resp.data;
      this.allKnowledge = resp.data;
      })
    },
    filterKnowledge(){
      this.Contentloading = true;
      let token = localStorage.getItem('token');
      this.searchResult = false;
     getSectorsKnowledge('Sectors','knowledgeSectors',token,this.selctedSector,['knowledge','sector'])
     .then(
       resp=>{
         this.items = resp.data.map(sector=>{
           let data = sector.knowledge;
           this.Contentloading = false;
           data.sector = sector.sector;
           return data;
         });
      this.allKnowledge = this.items;
       }
     )
    },
    changeStatus(id){
      this.selctedSector = id;
     
    },
    routeTo(route,knowledge){
      if(route == "knowledge_materials"){
        this.$store.commit("set",["sigleKnowledge",knowledge])
        this.$router.push(route+"/"+knowledge.id);
      }else{
        this.$router.push(route);
      }
      
    },
    randomIndex() {
      if(item){
      return Math.floor(Math.random() * this.items.length);}
    },
    all(){
      this.items = this.allKnowledge;
    },
    video() {
      let tempKnowledge = this.allKnowledge.filter(Knowledge => Knowledge.file_type == 'video');
      this.items = tempKnowledge;
      
    },
    pdf() {
      this.items = this.allKnowledge.filter(Knowledge=>Knowledge.file_type == 'pdf');;
    },
    shuffle() {
      this.items = _.shuffle(this.items);
    },
  },
};
</script>
<style lang="scss">


button {
  margin-right: 10px;
}
.demo p {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-bottom: calc(var(--bs-gutter-x) / -2);
  margin-left: calc(var(--bs-gutter-x) / -2);
}
.list-complete-item {
  transition: all 0.8s ease;
  // display: inline-block;
  margin-bottom: 10px;
}
.pdf-card-kn{
  border:none !important;
  box-shadow: 0px 0 8px rgb(1 41 112 / 42%);
}
.pdf-card-kn:hover{
 box-shadow: 0px 0 20px rgb(1 41 112 / 42%);
 background: #013289;
}
.list-complete-enter-from,
.list-complete-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

.list-complete-leave-active {
  position: absolute;
}
</style>