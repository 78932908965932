<template >
    <div>
        <b-container style="height:95vh" class="center-element">
               <div class="row center-element">
<img src="@/assets/img/FC_Preload.gif" class="mylogo "  style="width: 30%;opacity:0.9"/>
              
            
            </div>
            </b-container>
    </div>
</template>
<script>
export default {
    
}
</script>
<style >
    .mylogo{
  animation: rotation 5s infinite;
}
.my{
  animation: fadein 5s infinite linear;
}
@keyframes fadein {
  from { opacity: 0}
  to   { opacity: 1}
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
</style>